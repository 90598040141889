import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Home from '../views/Home'
import Locations from '../views/Locations'
import Settings from '../views/Settings'
import {drawerWidth} from '../config/defaults'

export default function Content(props) {
  return (
    <Box
      sx={{
        m: '25px',
        '@media (min-width:769px)': {
          marginLeft: `calc(25px + ${drawerWidth}px)`,
        }
      }}
    >
      <Toolbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Box>
  )
}